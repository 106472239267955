import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { ConnectionProvider, WalletProvider, useWallet } from '@solana/wallet-adapter-react';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { UnsafeBurnerWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';

import React, { FC, ReactNode, useMemo, useEffect } from 'react';

require('@solana/wallet-adapter-react-ui/styles.css');

const Header: FC = () => {
  return (
      <Context>
          <Content />
      </Context>
  );
};
export default Header;

const Context: FC<{ children: ReactNode }> = ({ children }) => {
  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  const network = WalletAdapterNetwork.Devnet;

  // You can also provide a custom RPC endpoint.
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);

  const wallets = useMemo(
      () => [
          new UnsafeBurnerWalletAdapter(),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [network]
  );

  return (
      <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect>
              <WalletModalProvider>{children}</WalletModalProvider>
          </WalletProvider>
      </ConnectionProvider>
  );
};

const Content: FC = () => {
  // Get the current URL
  const url = window.location.href;
  
  // Determine the value of demo based on the URL
  let demo = "";
  if (url.includes('test')) {
      demo = "DEMO WEBSITE Abhay";
  }

  // Set the title dynamically
  document.title = `${demo} - $ZOB MEME Landing Page Yo!`;
  // Use the useWallet hook to access wallet properties
  const { publicKey } = useWallet();
  // Function to send publicKey to the backend
  const sendPublicKeyToBackend = async (publicKey: string) => {
    try {
      const response = await fetch('_ajax_save_user.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ publicKey })
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('PublicKey sent successfully:', data);
    } catch (error) {
      console.error('Failed to send publicKey:', error);
    }
  };

  // Use useEffect to send publicKey when it changes
  useEffect(() => {
    if (publicKey) {
      sendPublicKeyToBackend(publicKey.toBase58());
    }
  }, [publicKey]);

  return (
    <div className="header">
      <div className="container">
        <div className="row">
        <div className="col-12 ">
            <div className="header-wrap d-flex align-items-center justify-content-between">
            <a href="/" className="header-logo">
                <img src="assets/img/logo.png" className="d-none d-sm-block" alt="" />
                <img src="assets/img/favicon.png" className="d-sm-none" alt="" />
            </a>
            <nav className="header-menu">
                <a href="/" className="header-logo d-lg-none">
                    <img src="assets/img/logo.png" alt="" />
                </a>
                <ul>
                    <li><a href="#about">About $ZOB</a></li>
                    <li><a href="#tokenomic">Tokenomics</a></li>
                    <li><a href="#chart">Chart</a></li>
                    <li><a href="#presale">PreSale</a></li>
                </ul>
                <div className="close-btn  d-lg-none">
                <svg className="svg-icon" style={{ width: '1em', height: '1em', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <path d="M512 981.333333C253.866667 981.333333 42.666667 770.133333 42.666667 512S253.866667 42.666667 512 42.666667s469.333333 211.2 469.333333 469.333333-211.2 469.333333-469.333333 469.333333z m0-853.333333C300.8 128 128 300.8 128 512s172.8 384 384 384 384-172.8 384-384S723.2 128 512 128z" fill="currentColor" />
                    <path d="M332.8 691.2c8.533333 8.533333 19.2 12.8 29.866667 12.8s21.333333-4.266667 29.866666-12.8l119.466667-119.466667 119.466667 119.466667c8.533333 8.533333 19.2 12.8 29.866666 12.8s21.333333-4.266667 29.866667-12.8c17.066667-17.066667 17.066667-42.666667 0-59.733333L571.733333 512l119.466667-119.466667c17.066667-17.066667 17.066667-42.666667 0-59.733333-17.066667-17.066667-42.666667-17.066667-59.733333 0L512 452.266667l-119.466667-119.466667c-17.066667-17.066667-42.666667-17.066667-59.733333 0-17.066667 17.066667-17.066667 42.666667 0 59.733333l119.466667 119.466667-119.466667 119.466667c-17.066667 17.066667-17.066667 42.666667 0 59.733333" fill="currentColor" />
                </svg>
                </div>
                <div className="hideMenuBtn d-flex align-items-center  d-lg-none">
                    <WalletMultiButton />
                </div>
            </nav>
            <div className="header-btn  d-flex align-items-center">
                <WalletMultiButton />
                <div className="menu-btn d-lg-none">
                  <svg className="svg-icon" style={{ width: '1em', height: '1em', verticalAlign: 'middle', fill: 'currentColor', overflow: 'hidden' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M867.995 459.647h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z" />
                      <path
                          d="M867.995 763.291h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353z" />
                      <path
                          d="M156.005 260.709h711.99c27.921 0 52.353-24.434 52.353-52.353s-24.434-52.353-52.353-52.353h-711.99c-27.921 0-52.353 24.434-52.353 52.353s24.434 52.353 52.353 52.353z" />
                  </svg>
                </div>
            </div>
            </div>
        </div>
        </div>
      </div>
    </div>
  );
};
