import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8 text-center">
                        <div className="footer-img">
                            <img src="assets/img/footer-img.png" alt="" />
                        </div>
                        <div className="footer-content">
                            <div className="footer-content-header">
                                <h4>ZOB will rule the meme world!</h4>
                            </div>
                            <p>Lately, the crypto playground has been swarming with SOL memecoins looking for trouble. What this wild bunch really needs is a boss, a king of the memes—and guess what? Zob is strutting in to claim the crown! </p>
                            <div className="social-link">
                                <ul>
                                    <li>
                                        <a href="https://twitter.com/zobmeme" target="_blank">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_0_116)">
                                                <path d="M12.8025 11.5903L18.2034 19.3155H15.9868L11.5796 13.0118V13.0114L10.9326 12.086L5.78419 4.72168H8.00072L12.1554 10.665L12.8025 11.5903Z" fill="white"/>
                                                <path d="M21.4067 0H2.59319C1.16101 0 -6.10352e-05 1.16108 -6.10352e-05 2.59325V21.4067C-6.10352e-05 22.8389 1.16101 24 2.59319 24H21.4067C22.8389 24 23.9999 22.8389 23.9999 21.4067V2.59325C23.9999 1.16108 22.8389 0 21.4067 0ZM15.3079 20.3525L10.8481 13.8617L5.26429 20.3525H3.82116L10.2073 12.9295L3.82116 3.63521H8.69197L12.9151 9.78152L18.2026 3.63521H19.6457L13.5561 10.7139H13.5557L20.1787 20.3525H15.3079Z" fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_0_116">
                                                    <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://t.me/zobmeme" target="_blank">
                                        <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M22.6312 23.7364C23.0162 24.009 23.5123 24.0771 23.9546 23.9098C24.3969 23.7412 24.7221 23.3634 24.8201 22.9056C25.8589 18.0234 28.3789 5.66627 29.3245 1.22524C29.3962 0.890522 29.2767 0.542651 29.0137 0.319106C28.7507 0.0955598 28.3861 0.0310072 28.0609 0.151746C23.0485 2.00706 7.61184 7.79892 1.30238 10.1336C0.901911 10.2818 0.641306 10.6668 0.654456 11.0887C0.668801 11.5119 0.953314 11.8789 1.36335 12.0021C4.1929 12.8484 7.90711 14.0259 7.90711 14.0259C7.90711 14.0259 9.64287 19.2679 10.5478 21.9337C10.6614 22.2684 10.9232 22.5314 11.2687 22.6223C11.6129 22.7119 11.9811 22.6175 12.2382 22.3748C13.6918 21.0025 15.9392 18.8806 15.9392 18.8806C15.9392 18.8806 20.2093 22.0114 22.6312 23.7364ZM9.46954 13.3637L11.4767 19.984L11.9226 15.7916C11.9226 15.7916 19.6773 8.79711 24.098 4.81034C24.2271 4.69319 24.2451 4.49714 24.1375 4.35966C24.0311 4.22219 23.835 4.18991 23.688 4.28316C18.5644 7.55505 9.46954 13.3637 9.46954 13.3637Z" fill="white"/>
                                        </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">
                                            <img src="assets/img/footer-icon-3.svg" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid p-xxxl-0">
                <div className="row">
                    <div className="col-12">
                        <div className="copyRight text-center">
                            <p>© W.O.K Corp CopyRight {new Date().getFullYear()} - All Rights Reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
